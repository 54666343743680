import { Module } from "vuex";
import { RootState } from "./root-state.model";
import { StateAppModel } from "./models";
import { stateApp as state } from "./state";
import { gettersApp as getters } from "./getters";
import { mutationsApp as mutations  } from "./mutations";
import { actionsApp as actions } from "./actions";

const namespaced = true;

export const moduleStoreApp: Module<StateAppModel, RootState> = {
  namespaced,
  state,
  getters,
  mutations,
  actions
};

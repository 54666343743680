import { Module } from "vuex";
import { RootState } from "../root-state.model";
import { stateAdmin as state } from "./state";
import { gettersAdmin as getters } from "./getters";
import { mutationsAdmin as mutations } from "./mutations";
import { actionsAdmin as actions } from "./actions";
import { StateAdminModel } from "./models";

const namespaced = true;

export const moduleStoreAdmin: Module<StateAdminModel, RootState> = {
  namespaced,
  state,
  getters,
  mutations,
  actions
};

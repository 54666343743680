
























import Vue from "vue";
import { mapGetters } from "vuex";

import RoleItem from "./Role-Item.vue";
import CreatorRole from "./Creator-Role.vue";
import { MODULE_STORE_ADMIN } from "../../../store/admin/types";
import { GET_ROLES } from "../../../store/share-types";

export default Vue.extend({
  components: {
    RoleItem,
    CreatorRole
  },
  computed: mapGetters(
      MODULE_STORE_ADMIN,
      [GET_ROLES]
  ),
  data() {
    return {
      isCreatingRole: false
    };
  },
  methods: {
    hideCreatorRole() {
      this.isCreatingRole = false;
    }
  }
});




















import Vue from "vue";
import { mapGetters, mapMutations } from "vuex";
import { MODULE_STORE_ADMIN, GET_GROUPS, SET_SELECTED_GROUP } from "../../../store/admin/types";
export default Vue.extend({
  computed: mapGetters(
    MODULE_STORE_ADMIN,
    [GET_GROUPS]
  ),
  data() {
    return {
      selectedGroup: ''
    }
  },
  methods: {
    ...mapMutations(
        MODULE_STORE_ADMIN,
        [SET_SELECTED_GROUP]
    ),
    selectGroup() {
      this[SET_SELECTED_GROUP](this.selectedGroup);
    },
    getGroupFilter() {
      return [{text: '', value: ''}].concat(this.getGroups);
    }
  }
});














import Vue from "vue";
import { mapGetters, mapActions, mapMutations } from "vuex";

import FailedApplication from "@/components/Failed-Application.vue";
import SuccessApplication from "@/components/Success-Application.vue";
import Form from "@/components/Form.vue";
import { GET_NUMBER_OF_APPLICATION, GET_SERVICES_BY, MODULE_STORE_APP, RECEIVE_CATEGORIES, RECEIVE_LOCAL_OF_UI, RECEIVE_ROLES, SWITCH_LANG } from "../store/types";

export default Vue.extend({
  components: {
    FailedApplication,
    SuccessApplication,
    Form
  },
  computed: mapGetters(
    MODULE_STORE_APP,
    [GET_NUMBER_OF_APPLICATION]
  ),
  async mounted() {
    const query = this.$route.query;
    let lang = query.lang as string;
    if (!Object.keys(query).length || !query.lang) {
      this.$router.push("/?lang=en"); // a redirect path by default
      lang = "en";
    }
    this[SWITCH_LANG](lang);
    await this[RECEIVE_LOCAL_OF_UI]();
    await this[RECEIVE_ROLES]();
    await this[RECEIVE_CATEGORIES]();
    await this[GET_SERVICES_BY]();
  },
  methods: {
    ...mapActions(
      MODULE_STORE_APP,
      [
        RECEIVE_LOCAL_OF_UI,
        RECEIVE_ROLES,
        RECEIVE_CATEGORIES,
        GET_SERVICES_BY
      ]
    ),
    ...mapMutations(
      MODULE_STORE_APP,
      [
        SWITCH_LANG
      ]
    )
  }
});

import { GetterTree } from "vuex";
import { AxiosInstance } from "axios";
import { RootState } from "./root-state.model";
import { OptionMenuModel, StateAppModel } from "./models";
import { 
  GET_LANG, 
  GET_LANG_LIST, 
  GET_AXIOS, 
  GET_CATEGORY, 
  GET_ROLE, 
  GET_LOCAL_OF_UI, 
  GET_NUMBER_OF_APPLICATION, 
  GET_VISIBLE_ERROR_ALERT 
} from "./types";
import {
  GET_SERVICES, 
  GET_ROLES, 
  GET_CATEGORIES
} from "./share-types";

export const gettersApp: GetterTree<StateAppModel, RootState> = {
  [GET_LANG]: (state): string => {
    return state.lang;
  },
  [GET_LANG_LIST]: (state): OptionMenuModel[] => {
    return state.langList;
  },
  [GET_ROLES]: (state: StateAppModel): OptionMenuModel[] => {
    return state.roles;
  },
  [GET_CATEGORIES]: (state: StateAppModel): OptionMenuModel[] => {
    return state.categories;
  },
  [GET_SERVICES]: (state: StateAppModel):  OptionMenuModel[] => {
    return state.services;
  },
  [GET_AXIOS]: (state: StateAppModel): AxiosInstance => {
    return state.axios;
  },
  [GET_CATEGORY]: (state:StateAppModel): string => {
    return state.category;
  },
  [GET_ROLE]: (state: StateAppModel): string => {
    return state.role;
  },
  [GET_LOCAL_OF_UI]: (state: StateAppModel) => {
    return state.localOfUi;
  },
  [GET_NUMBER_OF_APPLICATION]: (state: StateAppModel) => {
    return state.numberOfApplication;
  },
  [GET_VISIBLE_ERROR_ALERT]: (state: StateAppModel) => {
    return state.visisbleErrorAlert;
  }
};

































import Vue from "vue";
import { mapActions } from "vuex";
import { MODULE_STORE_ADMIN, SAVE_ROLE } from "../../../store/admin/types";

export default Vue.extend({
  computed: {
    hasName(): boolean {
      return Boolean(this.nameRu) && Boolean(this.nameEn);
    }
  },
  data() {
    return {
      preloaderSaving: false,
      nameRu: "",
      nameEn: ""
    };
  },
  methods: {
    ...mapActions(
        MODULE_STORE_ADMIN,
        [SAVE_ROLE]
    ),
    async save() {
      this.preloaderSaving = true;
      const role = this.getRole();
      await this[SAVE_ROLE](role);
      this.reset();
      this.preloaderSaving = false;
    },
    getRole() {
      return {
        id: Date.now(),
        name: {
          ru: this.nameRu,
          en: this.nameEn
        }
      };
    },
    reset() {
      this.nameRu = "";
      this.nameEn = "";
      this.$emit("hideCreatorRole");
    }
  }
});

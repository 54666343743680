import Vue from "vue";

import {
  LayoutPlugin,
  FormGroupPlugin,
  ButtonPlugin,
  AlertPlugin,
  FormInputPlugin,
  FormSelectPlugin,
  FormRadioPlugin,
  FormPlugin,
  FormTextareaPlugin,
  SpinnerPlugin,
  LinkPlugin
} from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

Vue.use(LayoutPlugin);
Vue.use(FormPlugin);
Vue.use(FormTextareaPlugin);
Vue.use(SpinnerPlugin);
Vue.use(FormGroupPlugin);
Vue.use(ButtonPlugin);
Vue.use(AlertPlugin);
Vue.use(FormInputPlugin);
Vue.use(FormSelectPlugin);
Vue.use(FormRadioPlugin);
Vue.use(LinkPlugin);

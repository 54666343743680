import Vue from "vue";
import "./plugins/bootstrap-vue";
import { router } from "./router/main"
import App from "./App.vue";
import { store } from "@/store/main";

Vue.config.productionTip = false;

new Vue({
  store,
  router,
  render: (h) => h(App)
}).$mount("#app");

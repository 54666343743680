
































































import Vue from "vue";
import { mapActions, mapGetters } from "vuex";

import { 
  MAP_CATEGORIES_TO_OPTIONS_MENU,
  MAP_ROLES_TO_OPTIONS_MENU,
  MODULE_STORE_ADMIN, 
  SAVE_SERVICE,
  GET_GROUPS
} from "../../../store/admin/types";

export default Vue.extend({
  computed: {
    ...mapGetters(
      MODULE_STORE_ADMIN,
      [MAP_CATEGORIES_TO_OPTIONS_MENU, MAP_ROLES_TO_OPTIONS_MENU, GET_GROUPS]
    ),
    hasName(): boolean {
      return Boolean(this.nameRu) && Boolean(this.nameEn);
    }
  },
  data() {
    return {
      preloaderSaving: false,
      nameRu: "",
      nameEn: "",
      selectedCategory: "",
      selectedGroup: "",
      seletedRoles: []
    };
  },
  methods: {
    ...mapActions(
      MODULE_STORE_ADMIN,
      [SAVE_SERVICE]
    ),
    async save() {
      this.preloaderSaving = true;
      const service = this.getService();
      await this[SAVE_SERVICE](service);
      this.reset();
      this.preloaderSaving = false;
    },
    getService() {
      return {
        name: {
          ru: this.nameRu,
          en: this.nameEn
        },
        category: this.selectedCategory,
        group: this.selectedGroup,
        roles: this.seletedRoles
      };
    },
    reset() {
      this.nameRu = "";
      this.nameEn = "";
      this.$emit("hideCreatorService");
    }
  }
});

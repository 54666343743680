






















import Vue from "vue";
import { mapGetters } from "vuex";
import { GET_LOCAL_OF_UI, GET_NUMBER_OF_APPLICATION, MODULE_STORE_APP } from "../store/types";

export default Vue.extend({
  computed: mapGetters(
    MODULE_STORE_APP,
    [GET_LOCAL_OF_UI, GET_NUMBER_OF_APPLICATION]
  )
});

import { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import { ActionTree } from "vuex";
import { StateAppModel, TaskAddDto } from "./models";
import { RootState } from "./root-state.model";
import { 
  GET_AXIOS, 
  GET_SERVICES_BY, 
  SET_ROLES, 
  SET_SERVICES, 
  SET_CATEGORIES, 
  ADD_TASK, 
  GET_LANG, 
  GET_ROLE, 
  GET_CATEGORY, 
  RECEIVE_LOCAL_OF_UI,
  SET_LOCAL_OF_UI,
  SET_NUMBER_OF_APPLICATION,
  SET_VISIBLE_ERROR_ALERT,
  RECEIVE_ROLES,
  RECEIVE_CATEGORIES
} from "./types";

export const actionsApp: ActionTree<StateAppModel, RootState> = {
  [GET_SERVICES_BY]: async ({ commit, getters }) => {
    const category = getters[GET_CATEGORY];
    const role = getters[GET_ROLE];
    const lang = getters[GET_LANG];
    if (role && category) {
      const axios = getters[GET_AXIOS] as AxiosInstance;
      axios.get("/items", { params: { role, category, lang }})
        .then((result: AxiosResponse) => {
          commit(SET_SERVICES, result.data);
        })
        .catch((error: AxiosError) => {
          console.error(error.response);
        });
    }
  },
  [ADD_TASK]: async ({ commit, getters }, taskData: TaskAddDto) => {
    const axios = getters[GET_AXIOS] as AxiosInstance;
    const lang = getters[GET_LANG];
    try {
      const result = await axios.post("/task-add", { ...taskData, lang });
      commit(SET_NUMBER_OF_APPLICATION, result.data.id);
    } catch (error) {
      commit(SET_VISIBLE_ERROR_ALERT, true);
    }
  },
  [RECEIVE_LOCAL_OF_UI]: ({ commit, getters }) => {
    const axios = getters[GET_AXIOS] as AxiosInstance;
    const lang = getters[GET_LANG];
    axios.get("/localization-of-ui", { params: { lang } })
      .then((result: AxiosResponse) => {
        commit(SET_LOCAL_OF_UI, result.data);
      })
      .catch((error: AxiosError) => {
        console.error("Error: ", error.response);
      });
  },
  [RECEIVE_ROLES]: async ({ commit, getters }) => {
    const axios = getters[GET_AXIOS] as AxiosInstance;
    const lang = getters[GET_LANG];
    try {
      const result = await axios.get("/roles", { params: { lang } });
      commit(SET_ROLES, result.data.roles);
    } catch (error) {
      console.error("Error: ", error.response);
    }
  },
  [RECEIVE_CATEGORIES]: async ({ commit, getters }) => {
    const axios = getters[GET_AXIOS] as AxiosInstance;
    const lang = getters[GET_LANG];
    const role = getters[GET_ROLE];
    try {
      if (role) {
        const result = await axios.get("/categories", { params: { lang, role } });
        commit(SET_CATEGORIES, result.data.categories);
      }
    } catch (error) {
      console.error("Error: ", error.response);
    }
  }
};

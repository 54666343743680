

























































































































































import Vue from "vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
import emailValidator from "email-validator";
import {
  GET_LANG_LIST,
  MODULE_STORE_APP,
  GET_SERVICES_BY, 
  ADD_TASK,
  SET_CATEGORY, 
  SET_ROLE,
  GET_LOCAL_OF_UI,
  GET_VISIBLE_ERROR_ALERT,
  SET_VISIBLE_ERROR_ALERT,
  RECEIVE_ROLES,
  RECEIVE_CATEGORIES
} from "../store/types";
import {
  GET_ROLES,
  GET_SERVICES,
  GET_CATEGORIES
} from "../store/share-types"
import { FormModel } from "./models/form.model"

import "@/assets/form.css";

import { BFormFile, BBadge } from 'bootstrap-vue';
Vue.component('b-form-file', BFormFile);
Vue.component('b-badge', BBadge);

export default Vue.extend({
  computed: {
    ...mapGetters(
      MODULE_STORE_APP, 
      [
        GET_ROLES, 
        GET_CATEGORIES, 
        GET_LANG_LIST, 
        GET_SERVICES, 
        GET_LOCAL_OF_UI, 
        GET_VISIBLE_ERROR_ALERT
      ]
    ),
    validDataForm(): boolean {
      // check out only required data
      return  !!this.form.name
        && !!this.form.surname
        && !!this.form.email
        && !!this.form.role
        && !!this.form.category
        && !!this.form.service
        && !!this.form.description;
    }
  },
  data() {
    let files: Object[] = [];
    if (this.$route.query['email'] === undefined || this.$route.query['email'] === null) {
      this.$route.query['email'] = "";
    }
    return {
      show: true,
      preloader: false,
      disabled: false,
      form: {
        name: this.$route.query['name'],
        surname: this.$route.query['surname'],
        email: String(this.$route.query['email']),
        description: "",
        files: files,
        role: "",
        category: "",
        service: ""
      },
      approachFillForm: {
        name: false,
        surname: false,
        email: false,
        description: false,
        role: false,
        category: false,
        service: false
      }
    };
  },
  methods: {
    ...mapActions(
      MODULE_STORE_APP,
      [GET_SERVICES_BY, RECEIVE_ROLES, ADD_TASK, RECEIVE_CATEGORIES]
    ),
    ...mapMutations(
      MODULE_STORE_APP,
      [SET_CATEGORY, SET_ROLE, SET_VISIBLE_ERROR_ALERT]
    ),
    appendClassToInput(nameProp: keyof FormModel): string {
      if (nameProp === "email") {
        return this.getValidClass(this.validEmail(this.form[nameProp]));
      }
      return this.getValidClass(!!this.form[nameProp]);
    },
    getValidClass(valid: boolean): string {
      return valid ? "is-valid" : "is-invalid";
    },
    validEmail(email: string): boolean {
      return emailValidator.validate(email);
    },
    async send() {
      this.toggleRequest();
      await this[ADD_TASK](this.form);
      this.reset();
      this.toggleRequest();
    },
    reset() {
      if (this[GET_VISIBLE_ERROR_ALERT]) {
        return;
      }
      this.clearForm();
      this.clearAproachFillForm();
    },
    toggleRequest() {
      this.preloader = !this.preloader;
      this.disabled = !this.disabled;
    },
    hideErrorAlert() {
      this[SET_VISIBLE_ERROR_ALERT](false);
    },
    clearForm() {
      this.form.name = "";
      this.form.surname = "";
      this.form.email = "";
      this.form.description = "";
      this.form.role = "";
      this.form.category = "";
      this.form.service = "";
      this.show = false
      this.$nextTick(() => {
        this.show = true
      });
    },
    clearAproachFillForm() {
      this.approachFillForm.name = false;
      this.approachFillForm.surname = false;
      this.approachFillForm.email = false;
      this.approachFillForm.description = false;
      this.approachFillForm.role = false;
      this.approachFillForm.category = false;
      this.approachFillForm.service = false;
    },
    async selectCategory() {
      this[SET_CATEGORY](this.form.category);
      await this[GET_SERVICES_BY]();
    },
    async selectRole() {
      this[SET_ROLE](this.form.role);
      await this[RECEIVE_CATEGORIES]();
      await this[GET_SERVICES_BY]();
    },
    setFileData(files: Array<File>) {
      this.form.files = [];
      for(let file of files) {
        const reader = new FileReader();
        reader.onloadend = () => {
          if (reader.result) {
            this.form.files.push({
              fileName: file.name,
              fileContent: reader.result
            });
          }
        };
        reader.readAsBinaryString(file);
      }
      
    }
  }
});

import { AxiosInstance, AxiosResponse } from "axios";
import { ActionTree } from "vuex";
import { RootState } from "../root-state.model";
import {
  GET_AXIOS,
  MODULE_STORE_APP, 
  SET_CATEGORIES,
  SET_CATEGORY,
  SET_ROLES,
  SET_SERVICES
} from "../types";
import {
  GET_CATEGORIES,
  GET_ROLES,
  GET_SERVICES
} from "../share-types";
import {
  DynamicCategory,
  StateAdminModel,
  DynamicService,
  DynamicRole
} from "./models";
import {
  ADD_CATEGORY,
  ADD_SERVICE,
  REMOVE_CATEGORY,
  SAVE_CATEGORY,
  UPDATE_CATEGORY,
  UPDATE_SERVICE,
  SET_SERVICE,
  SAVE_SERVICE,
  REMOVE_SERVICE,
  SAVE_ROLE,
  ADD_ROLE,
  UPDATE_ROLE,
  SET_ROLE,
  REMOVE_ROLE,
  GET_ENTITIES,
  SET_GROUPS
} from "./types";

export const actionsAdmin: ActionTree<StateAdminModel, RootState> = {
  [GET_ENTITIES]: async ({ commit, rootGetters }): Promise<void> => {
    const axios = rootGetters[`${MODULE_STORE_APP}/${GET_AXIOS}`] as AxiosInstance;
    try {
      const response: AxiosResponse = await axios.get("/admin");
      const entities = response.data;
      commit(SET_CATEGORIES, entities.categories);
      commit(SET_ROLES, entities.roles);
      commit(SET_SERVICES, entities.services);
      commit(SET_GROUPS, entities.groups);
    } catch (error) {
      console.log(error);
    }
  },
  [UPDATE_CATEGORY]: async ({ commit, rootGetters }, category: DynamicCategory): Promise<void> => {
    const axios = rootGetters[`${MODULE_STORE_APP}/${GET_AXIOS}`] as AxiosInstance;
    try {
      await axios.patch(`/admin/categories/${category._id}`, category);
      commit(SET_CATEGORY, category);
    } catch (error) {
      console.error(error.response);
    }
  },
  [SAVE_CATEGORY]: async ({ commit, rootGetters }, category: DynamicCategory): Promise<void> => {
    const axios = rootGetters[`${MODULE_STORE_APP}/${GET_AXIOS}`] as AxiosInstance;
    try {
      const { data } = await axios.post("/admin/categories", category);
      commit(ADD_CATEGORY, data.result);
    } catch (error) {
      console.error(error.response);
    }
  },
  [REMOVE_CATEGORY]: async ({ commit, getters, rootGetters }, deletingCategory: DynamicCategory): Promise<void> => {
    const axios = rootGetters[`${MODULE_STORE_APP}/${GET_AXIOS}`] as AxiosInstance;
    try {
      await axios.delete(`/admin/categories/${deletingCategory._id}`);
      const categories = getters[GET_CATEGORIES] as DynamicCategory[];
      const result = categories.filter((category: DynamicCategory) => {
        return category._id !== deletingCategory._id;
      });
      commit(SET_CATEGORIES, result);
    } catch (error) {
      console.error(error.response);
    }
  },
  [UPDATE_SERVICE]: async ({ commit, rootGetters }, service: DynamicService): Promise<void> => {
    const axios = rootGetters[`${MODULE_STORE_APP}/${GET_AXIOS}`] as AxiosInstance;
    try {
      await axios.put(`/admin/services/${service._id}`, service);
      commit(SET_SERVICE, service);
    } catch (error) {
      console.error(error.response);
    }
  },
  [SAVE_SERVICE]: async ({ commit, rootGetters }, service: DynamicService): Promise<void> => {
    const axios = rootGetters[`${MODULE_STORE_APP}/${GET_AXIOS}`] as AxiosInstance;
    try {
      const { data } = await axios.post("/admin/services", service);
      commit(ADD_SERVICE, data.result);
    } catch (error) {
      console.error(error.response);
    }
  },
  [REMOVE_SERVICE]: async ({ commit, getters, rootGetters }, deletingService: DynamicService): Promise<void> => {
    const axios = rootGetters[`${MODULE_STORE_APP}/${GET_AXIOS}`] as AxiosInstance;
    try {
      await axios.delete(`/admin/services/${deletingService._id}`);
      const services = getters[GET_SERVICES] as DynamicService[];
      const result = services.filter((service: DynamicService) => {
        return service._id !== deletingService._id;
      });
      commit(SET_SERVICES, result);
    } catch (error) {
      console.error(error.response);
    }
  },
  [UPDATE_ROLE]: async ({ commit, rootGetters }, updatingRole: DynamicRole): Promise<void> => {
    const axios = rootGetters[`${MODULE_STORE_APP}/${GET_AXIOS}`] as AxiosInstance;
    try {
      await axios.patch(`/admin/roles/${updatingRole._id}`, updatingRole);
      commit(SET_ROLE, updatingRole);
    } catch (error) {
      console.error(error.response);
    }
  },
  [SAVE_ROLE]: async ({ commit, rootGetters }, role: DynamicRole): Promise<void> => {
    const axios = rootGetters[`${MODULE_STORE_APP}/${GET_AXIOS}`] as AxiosInstance;
    try {
      const { data } = await axios.post("/admin/roles", role);
      commit(ADD_ROLE, data.result);
    } catch (error) {
      console.error(error.response);
    }
  },
  [REMOVE_ROLE]: async ({ commit, getters, rootGetters }, deletingRole: DynamicRole) => {
    const axios = rootGetters[`${MODULE_STORE_APP}/${GET_AXIOS}`] as AxiosInstance;
    try {
      await axios.delete(`/admin/roles/${deletingRole._id}`);
      const roles = getters[GET_ROLES] as DynamicRole[];
      const result = roles.filter((role: DynamicRole) => {
        return role._id !== deletingRole._id;
      });
      commit(SET_ROLES, result);
    } catch (error) {
      console.error(error.response);
    }
  }
};

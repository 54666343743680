
















import Vue from "vue";
import { mapGetters, mapMutations } from "vuex";
import { 
  GET_LOCAL_OF_UI, 
  GET_VISIBLE_ERROR_ALERT, 
  MODULE_STORE_APP, 
  SET_VISIBLE_ERROR_ALERT 
} from "../store/types";

export default Vue.extend({
  computed: mapGetters(
    MODULE_STORE_APP,
    [GET_LOCAL_OF_UI, GET_VISIBLE_ERROR_ALERT]
  ),
  methods: {
    ...mapMutations(
      MODULE_STORE_APP,
      [SET_VISIBLE_ERROR_ALERT]
    ),
    hide() {
      this[SET_VISIBLE_ERROR_ALERT](false);
    }
  }
});

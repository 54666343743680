































import Vue from "vue";
import { mapActions } from "vuex";
import { MODULE_STORE_ADMIN, SAVE_CATEGORY } from "../../../store/admin/types";

export default Vue.extend({
  computed: {
    hasName(): boolean {
      return Boolean(this.nameRu) && Boolean(this.nameEn);
    }
  },
  data() {
    return {
      preloaderSaving: false,
      nameRu: "",
      nameEn: ""
    };
  },
  methods: {
    ...mapActions(
      MODULE_STORE_ADMIN, 
      [SAVE_CATEGORY]
    ),
    async save() {
      this.preloaderSaving = true;
      const category = this.getCategory();
      await this[SAVE_CATEGORY](category);
      this.reset();
      this.preloaderSaving = false;
    },
    getCategory() {
      return {
        id: Date.now(),
        name: {
          ru: this.nameRu,
          en: this.nameEn
        }
      };
    },
    reset() {
      this.nameRu = "";
      this.nameEn = "";
      this.$emit("hideCreatorCategory");
    }
  }
});

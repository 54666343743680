











import Vue from "vue";
import { mapActions } from "vuex";

import Categories from "@/components/admin/categories/Categories.vue";
import Services from "@/components/admin/services.screen/Services.vue";
import Roles from "@/components/admin/roles/Roles.vue";
import Navigation from "@/components/admin/Navigation.vue";

import "../assets/admin.css";
import { GET_ENTITIES, MODULE_STORE_ADMIN } from "../store/admin/types";

export default Vue.extend({
  components: {
    Categories,
    Services,
    Navigation,
    Roles
  },
  async mounted() {
    await this[GET_ENTITIES]();
  },
  methods: {
    ...mapActions(
      MODULE_STORE_ADMIN,
      [GET_ENTITIES]
    )
  }
});

import { MutationTree } from "vuex";
import { SET_CATEGORIES, SET_SERVICES, SET_CATEGORY, SET_ROLES } from "../types";
import { 
  DynamicCategory, 
  GroupsB24, 
  DynamicRole, 
  DynamicService, 
  StateAdminModel 
} from "./models";
import { 
  ADD_CATEGORY, 
  ADD_ROLE, 
  ADD_SERVICE, 
  SET_GROUPS, 
  SET_ROLE, 
  SET_SERVICE,
  SET_SELECTED_GROUP
} from "./types";

export const mutationsAdmin: MutationTree<StateAdminModel> = {
  [ADD_CATEGORY]: (state: StateAdminModel, category: DynamicCategory): void => {
    state.categories.unshift(category);
  },
  [SET_CATEGORY]: (state: StateAdminModel, addedCategory: DynamicCategory): void => {
    state.categories.forEach((category: DynamicCategory): void => {
      if (category._id === addedCategory._id) {
        category = Object.assign(category, addedCategory);
      }
    });
  },
  [SET_CATEGORIES]: (state: StateAdminModel, categories: DynamicCategory[]): void => {
    state.categories = categories;
  },
  [SET_SERVICES]: (state, services: DynamicService[]): void => {
    state.services = services;
  },
  [ADD_SERVICE]: (state: StateAdminModel, addingServices: DynamicService): void => {
    state.services.unshift(addingServices);
  },
  [SET_SERVICE]: (state: StateAdminModel, updatedService: DynamicService): void => {
    state.services.forEach((service: DynamicCategory): void => {
      if (service._id === updatedService._id) {
        service = Object.assign(service, updatedService);
      }
    });
  },
  [ADD_ROLE]: (state: StateAdminModel, addingRoles: DynamicRole): void => {
    state.roles.unshift(addingRoles);
  },
  [SET_ROLE]: (state: StateAdminModel, updatedRole: DynamicRole): void => {
    state.roles.forEach((role: DynamicRole): void => {
      if (role._id === updatedRole._id) {
        role = Object.assign(role, updatedRole);
      }
    });
  },
  [SET_ROLES]: (state, roles: DynamicRole[]): void => {
    state.roles = roles;
  },
  [SET_GROUPS]: (state: StateAdminModel, groups: GroupsB24[]): void => {
    state.groups = groups;
  },
  [SET_SELECTED_GROUP]: (state: StateAdminModel, selectedGroup: string): void => {
    state.selectedGroup = selectedGroup;
  }
};

// module name
export const MODULE_STORE_APP = "moduleStoreApp";

// getters
export const GET_LANG = "getLang";
export const GET_LANG_LIST = "getLangList";
export const GET_AXIOS = "getAxios";
export const GET_CATEGORY = "getCategory";
export const GET_ROLE = "getRole";
export const GET_LOCAL_OF_UI = "getLocalOfUi";
export const GET_NUMBER_OF_APPLICATION = "getNumberOfApplication";
export const GET_VISIBLE_ERROR_ALERT = "getVisibleErrorAlert";

// mutations
export const SWITCH_LANG = "switchLang";
export const SET_CATEGORIES = "setCategories";
export const SET_ROLES = "setRoles";
export const SET_SERVICES = "setServices";
export const SET_CATEGORY = "setCategory";
export const SET_ROLE = "setRole";
export const SET_LOCAL_OF_UI = "setLocalOfUi";
export const SET_NUMBER_OF_APPLICATION = "setNumberOfApplication";
export const SET_VISIBLE_ERROR_ALERT = "setVisibleErrorAlert";

// actions
export const GET_SERVICES_BY = "getServicesBy";
export const ADD_TASK = "addTask";
export const RECEIVE_LOCAL_OF_UI = "receiveLocalOfUi";
export const RECEIVE_ROLES = "receiveRoles";
export const RECEIVE_CATEGORIES = "receiveCategories";

import axios from "axios";
import { StateAppModel, LocalOfUiModel } from "./models";

export const stateApp: StateAppModel = {
  axios: axios.create({
    baseURL: process.env.VUE_APP_BACKEND_URI,
  }),
  lang: "en",
  langList: [
    {
      value: "en",
      text: "English"
    },
    {
      value: "ru",
      text: "Russian"
    }
  ],
  localOfUi: {} as LocalOfUiModel,
  numberOfApplication: "",
  visisbleErrorAlert: false,
  category: "",
  role: "",
  services: [],
  categories: [],
  roles: []
};

import { RouteConfig } from "vue-router";
import App from "../App.vue";
import Form from "../pages/Form-App.vue";
import Admin from "../pages/Admin.vue";
import Categories from "../components/admin/categories/Categories.vue";
import Services from "../components/admin/services.screen/Services.vue";
import Roles from "../components/admin/roles/Roles.vue"
import NotAuth from "../components/admin/Not-Auth.vue";

import axios from "axios";
axios.defaults.withCredentials = true;

export const routes: RouteConfig[] = [
  {
    name: "home",
    path: "/",
    component: App,
    children: [
      {
        name: "form",
        path: "",
        component: Form
      },
      {
        name: "admin",
        path: "admin",
        component: Admin,
        children: [
          {
            path: "categories",
            name: "categories",
            component: Categories
          },
          {
            path: "services",
            name: "services",
            component: Services
          },
          {
            name: "roles",
            path: "roles",
            component: Roles
          }
        ],
        async beforeEnter(to, from, next) {
          try {
            await axios.get(`${process.env.VUE_APP_BACKEND_URI}/admin/authorized`);
            next();
          } catch(e) {
            next({ name: "not_auth" });
          }
        }
      },
      {
        name: "not_auth",
        path: "not-auth",
        component: NotAuth
      }
    ]
  },
  { // when path is incorrect there redirect to main component
    name: "404",
    path: "*",
    component: Form
  }
];

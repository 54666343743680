





















import Vue from "vue";
import { mapGetters } from "vuex";
import { MODULE_STORE_ADMIN } from "../../../store/admin/types";
import { GET_SERVICES } from "../../../store/share-types";

import CreatorService from "./Creator-Service.vue";
import ServiceItem from "./Services-Item.vue";
import FilterServicesByGroup from "./FilterServicesByGroup.vue";

export default Vue.extend({
  components: {
    FilterServicesByGroup,
    CreatorService,
    ServiceItem
  },
  computed: mapGetters(
    MODULE_STORE_ADMIN,
    [GET_SERVICES]
  ),
  data() {
    return {
      isCreatingService: false
    };
  }
});

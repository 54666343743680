
























import Vue from "vue";
import { mapGetters } from "vuex";

import CategoryItem from "./Category-Item.vue";
import CreatorCategory from "./Creator-Category.vue";
import { MODULE_STORE_ADMIN } from "../../../store/admin/types";
import { GET_CATEGORIES } from "../../../store/share-types";

export default Vue.extend({
  components: {
    CategoryItem,
    CreatorCategory
  },
  computed: mapGetters(
    MODULE_STORE_ADMIN,
    [GET_CATEGORIES]
  ),
  data() {
    return {
      isCreatingCategory: false
    };
  },
  methods: {
    hideCreatorCategory() {
      this.isCreatingCategory = false;
    }
  }
});

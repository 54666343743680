import Vue from "vue";
import Vuex from "vuex";
import { RootState } from "./root-state.model";
import { moduleStoreApp } from "./module";
import { MODULE_STORE_APP } from "./types";
import { moduleStoreAdmin } from "./admin/module";
import { MODULE_STORE_ADMIN } from "./admin/types";

Vue.use(Vuex);

export const store = new Vuex.Store<RootState>({
  modules: {
    [MODULE_STORE_APP]: moduleStoreApp,
    [MODULE_STORE_ADMIN]: moduleStoreAdmin
  }
});





















































import Vue from "vue";
import { mapActions } from "vuex";
import { MODULE_STORE_ADMIN, REMOVE_ROLE, UPDATE_ROLE } from "../../../store/admin/types";

export default Vue.extend({
  props: {
    role: {
      type: Object,
      required: true
    }
  },
  computed: {
    hadChangingName(): boolean {
      return this.oldNameRu !== this.nameRu
          || this.oldNameEn !== this.nameEn
    }
  },
  data() {
    return {
      editNameEn: false,
      editNameRu: false,
      preloaderSaving: false,
      nameRu: "",
      nameEn: "",
      oldNameRu: "",
      oldNameEn: ""
    };
  },
  mounted() {
    this.nameRu = this.role.name.ru;
    this.nameEn = this.role.name.en;
    this.oldNameRu = this.role.name.ru;
    this.oldNameEn = this.role.name.en;
  },
  methods: {
    ...mapActions(
        MODULE_STORE_ADMIN,
        [UPDATE_ROLE, REMOVE_ROLE]
    ),
    update() {
      this.preloaderSaving = true;
      const name = this.getUpdatedName();
      const updatedRole = Object.assign(this.role, { name });
      this.updateRole(updatedRole);
      this.updateOldName();
      this.preloaderSaving = false;
    },
    getUpdatedName() {
      return {
        ru: this.nameRu,
        en: this.nameEn
      };
    },
    updateOldName() {
      this.oldNameRu = this.nameRu;
      this.oldNameEn = this.nameEn;
    },
    remove() {
      if (confirm(`Вы действительно хотите удалить роль "${this.nameRu}"?`)) {
        this[REMOVE_ROLE](this.role);
      }
    }
  }
});

import { MutationTree } from "vuex";
import { LocalOfUiModel, OptionMenuModel, StateAppModel } from "./models";
import { 
  SET_ROLES, 
  SET_SERVICES, 
  SET_CATEGORIES, 
  SWITCH_LANG, 
  SET_CATEGORY, 
  SET_ROLE, 
  SET_LOCAL_OF_UI, 
  SET_NUMBER_OF_APPLICATION, 
  SET_VISIBLE_ERROR_ALERT 
} from "./types";

export const mutationsApp: MutationTree<StateAppModel> = {
  [SWITCH_LANG]: (
    state: StateAppModel, 
    payload: string) => {
    state.lang = payload;
  },
  [SET_SERVICES]: (state: StateAppModel, payload: OptionMenuModel[]) => {
    state.services = payload;
  },
  [SET_CATEGORIES]: (state: StateAppModel, payload: OptionMenuModel[]) => {
    state.categories = payload;
  },
  [SET_ROLES]: (state: StateAppModel, payload: OptionMenuModel[]) => {
    state.roles = payload;
  },
  [SET_CATEGORY]: (state: StateAppModel, payload: string) => {
    state.category = payload;
  },
  [SET_ROLE]: (state: StateAppModel, payload: string) => {
    state.role = payload;
  },
  [SET_LOCAL_OF_UI]: (state: StateAppModel, payload: LocalOfUiModel) => {
    state.localOfUi = payload;
  },
  [SET_NUMBER_OF_APPLICATION]: (state: StateAppModel, payload: string) => {
    state.numberOfApplication = payload;
  },
  [SET_VISIBLE_ERROR_ALERT]: (state: StateAppModel, payload: boolean) => {
    state.visisbleErrorAlert = payload;
  }
};

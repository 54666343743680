// module name
export const MODULE_STORE_ADMIN = "moduleStoreAdmin";

// getters
export const MAP_CATEGORIES_TO_OPTIONS_MENU = "mapCategoriesToOptionsMenu";
export const MAP_ROLES_TO_OPTIONS_MENU = "mapRolesToOptionsMenu";
export const GET_GROUPS = "getGroups";

// mutations
export const ADD_CATEGORY = "addCategory";
export const ADD_SERVICE = "addService";
export const SET_SERVICE = "setService";
export const ADD_ROLE = "addRole";
export const SET_ROLE = "setRole";
export const SET_GROUPS = "setGroups";
export const SET_SELECTED_GROUP = "setSelectedGroup";

// actions
export const GET_ENTITIES = "getEntities";
export const UPDATE_CATEGORY = "updateCategory";
export const SAVE_CATEGORY = "saveCategory";
export const REMOVE_CATEGORY = "removeCategory";
export const UPDATE_SERVICE = "updateService";
export const SAVE_SERVICE = "saveService";
export const REMOVE_SERVICE = "removeService";
export const UPDATE_ROLE = "updateRole";
export const SAVE_ROLE = "saveRole";
export const REMOVE_ROLE = "removeRole";

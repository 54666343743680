






















































































import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import isEqual from "lodash.isequal";

import { 
  MAP_CATEGORIES_TO_OPTIONS_MENU,
  MAP_ROLES_TO_OPTIONS_MENU,
  MODULE_STORE_ADMIN, 
  REMOVE_SERVICE,
  GET_GROUPS,
  UPDATE_SERVICE
} from "../../../store/admin/types";

export default Vue.extend({
  props: {
    service: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters(
      MODULE_STORE_ADMIN,
      [MAP_CATEGORIES_TO_OPTIONS_MENU, MAP_ROLES_TO_OPTIONS_MENU, GET_GROUPS]
    ),
    hadChangingName(): boolean {
      return this.oldNameRu !== this.nameRu
        || this.oldNameEn !== this.nameEn
        || this.oldCategory !== this.category
        || this.oldGroup !== this.group
        || !isEqual(this.oldRoles, this.roles)
    }
  },
  data() {
    return {
      editNameRu: false,
      editNameEn: false,
      preloaderUpdating: false,
      nameRu: "",
      nameEn: "",
      oldNameRu: "",
      oldNameEn: "",
      category: "",
      oldCategory: "",
      group: "",
      oldGroup: "",
      roles: [],
      oldRoles: []
    };
  },
  mounted() {
    this.nameRu = this.service.name.ru;
    this.nameEn = this.service.name.en;
    this.oldNameRu = this.service.name.ru;
    this.oldNameEn = this.service.name.en;
    this.category = this.service.category;
    this.oldCategory = this.service.category;
    this.group = this.service.group;
    this.oldGroup = this.service.group;
    this.roles = this.service.roles;
    this.oldRoles = this.service.roles;
  },
  methods: {
    ...mapActions(
      MODULE_STORE_ADMIN,
      [UPDATE_SERVICE, REMOVE_SERVICE]
    ),
    remove() {
      if (confirm(`Вы действительно хотите удалить услугу "${this.nameRu}"?`)) {
        this[REMOVE_SERVICE](this.service);
      }
    },
    async update() {
      this.preloaderUpdating = true;
      const updatedService = this.getService();
      await this[UPDATE_SERVICE](Object.assign(this.service, updatedService));
      this.updateOldModels();
      this.preloaderUpdating = false;
    },
    getService() {
      return {
        name: {
          ru: this.nameRu,
          en: this.nameEn
        },
        category: this.category,
        group: this.group,
        roles: this.roles
      };
    },
    updateOldModels() {
      this.oldNameRu = this.nameRu;
      this.oldNameEn = this.nameEn;
      this.oldCategory = this.category;
      this.oldGroup = this.group;
      this.oldCategory = this.category;
      this.oldRoles = this.roles;
    }
  }
});

import { GetterTree } from "vuex";
import { OptionMenuModel } from "../models";
import { RootState } from "../root-state.model";
import { GET_CATEGORIES, GET_SERVICES, GET_ROLES } from "../share-types";
import { 
  StateAdminModel, 
  DynamicCategory, 
  DynamicService, 
  DynamicRole, 
  GroupsB24 
} from "./models";
import { 
  GET_GROUPS, 
  MAP_CATEGORIES_TO_OPTIONS_MENU, 
  MAP_ROLES_TO_OPTIONS_MENU
} from "./types";

export const gettersAdmin: GetterTree<StateAdminModel, RootState> = {
  [GET_CATEGORIES]: (state: StateAdminModel): DynamicCategory[] => {
    return state.categories;
  },
  [GET_SERVICES]: (state: StateAdminModel): DynamicService[] => {
    return state.services.filter(service=>{
      return state.selectedGroup === '' || service.group === state.selectedGroup;
    });
  },
  [GET_ROLES]: (state: StateAdminModel): DynamicRole[] => {
    return state.roles;
  },
  [GET_GROUPS]: (state: StateAdminModel): OptionMenuModel[] => {
    return state.groups.map((group: GroupsB24) => {
      return {
        text: group.NAME,
        value: group.NAME
      };
    });
  },
  [MAP_CATEGORIES_TO_OPTIONS_MENU]: (state: StateAdminModel): OptionMenuModel[] => {
    return state.categories.map((category: DynamicCategory) => {
      return {
        text: category.name.ru,
        value: category.name.en
      };
    });
  },
  [MAP_ROLES_TO_OPTIONS_MENU]: (state: StateAdminModel): OptionMenuModel[] => {
    return state.roles.map((role: DynamicRole) => {
      return {
        text: role.name.ru,
        value: role.name.en
      };
    });
  }
};
